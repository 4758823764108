<template>
  <section class="faq">
    <v-container class="mb-16">
      <p class="text-h5 text-lg-h4 font-weight-bold text-center mt-16 mb-8">Τρόποι πληρωμής</p>

      <v-divider class="mx-auto w-25 mb-16"></v-divider>

      <v-row>
        <v-col>

          <!-- Payments -->
          <p class="headline"><v-icon class="mr-2">fab fa-paypal</v-icon>Paypal</p>
          <p class="pl-8">
            Δεχόμαστε πληρωμές μέσω της Paypal, εφόσον διαθέτετε λογαριασμό στην υπηρεσία.
          </p>

          <v-divider class="w-25 my-15 mx-auto"></v-divider>

          <p class="headline"><v-icon class="mr-2">mdi-credit-card-outline</v-icon>Χρεωστική ή πιστωτική κάρτα</p>
          <p class="pl-8">
            Δεχόμαστε πληρωμές μέσω πιστωτικών ή χρεωστικών καρτών* Visa και MasterCard όλων των τραπεζών μεσω της υπηρεσίας
            Paypal. <br><strong>Δεν απαιτείται να διαθέτετε λογαριασμό Paypal.</strong>
          </p>

          <v-divider class="w-25 my-15 mx-auto"></v-divider>

          <p class="headline"><v-icon class="mr-2">mdi-bank</v-icon>Τραπεζική κατάθεση</p>
          <p class="pl-8">
            Μπορείτε να καταθέσετε τα χρήματα στο παρακάτω λογαριασμό της Εθνικής Τράπεζας<br><br>
            <strong>Δικαιούχος:</strong> ΔΕΛΑΚΗ ΚΑΤΙΝΑ ΑΘΑΝΑΣΙΟΣ
            <br>
            <strong>Συνδικαιούχος:</strong> ΔΕΛΑΚΗ ΜΑΡΙΝΑ ΓΕΩΡΓΙΟΣ
            <br>
            <strong>Αρ. Λογαριασμού:</strong> 875/742455-82
            <br>
            <strong>IBAN:</strong> GR53 0110 8750 0000 8757 4245 582
          </p>

          <p class="pt-8">
            * Τα στοιχεία της κάρτας σας σε περίπτωση αγοράς συνδρομής με αυτήν δεν αποθηκεύονται και δεν επεξεργάζονται από το σύστημα μας.
          </p>

        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  created () {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }
};
</script>
